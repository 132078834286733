import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { I18n } from 'aws-amplify';
import { format } from 'date-fns/fp';
import { createContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Flip, toast, ToastContainer } from 'react-toastify';

import { useQuery, useSubscription } from '@apollo/client';
import { Authenticator } from '@aws-amplify/ui-react';
import { GetDeliveryTicketsReq, KDSPermission } from '@calo/dashboard-types';
import { Box, LinearProgress, TableContainer } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { caloTheme } from './assets/themes/calo';
import { NotificationAlert, OfflineAlert, ProtectedRoute, Sidebar, SigninComponents, TopBar } from './components';
import { useListComponentByRangeQuery } from './hooks';
import {
	Country,
	DeliveryTicketStatus,
	DeliveryTicketType,
	DeliveryTicketWithIndex,
	Food,
	FoodComponent,
	KDSAppContext,
	Kitchen,
	ListKitchensQuery,
	NotificationType,
	RoutePaths,
	Session
} from './libs';
import {
	GET_SHOPPING_MENU_QUERY,
	LIST_DELIVERY_TICKETS_QUERY,
	LIST_FOOD_COMPONENTS_QUERY,
	LIST_FOODS_QUERY,
	LIST_KITCHENS_QUERY,
	SUBSCRIBE_TO_BATCH_UPDATE_FOODS_PORTIONING_STATUS_SUBSCRIPTION,
	SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION,
	SUBSCRIBE_TO_UPDATE_FOOD_COMPONENT_STATUS_SUBSCRIPTION,
	SUBSCRIBE_TO_UPDATE_FOOD_PORTIONING_SUBSCRIPTION,
	SUBSCRIBE_TO_UPDATE_SHOPPING_MENU_SUBSCRIPTION
} from './libs/graphQL';
import { getActiveWorkingDayDate, getKitchenFromLocalStorage, parseCognitoUser, useDocumentMedia } from './libs/utils';
import {
	AssemblyCheck,
	ComponentsCheck,
	CustomerComplaints,
	DriverDispatch,
	FileManagement,
	Home,
	LockTimeChanges,
	LogisticsChanges,
	MealsStatus,
	PortioningDisplay,
	PreDispatchArchive,
	PreDispatchCheck,
	ProductivityMetrics,
	QualityMetrics,
	QualityTracking,
	RecipeGuideBook,
	ShortageDisplay,
	ShortageReporting,
	SpecialRequest,
	WastageArchive,
	WastageTracking
} from './views';

export const AppContext = createContext<KDSAppContext>({
	isOffline: false,
	isSidebarOpened: true,
	isFilterOpen: false,
	user: {
		id: '',
		name: '',
		email: '',
		phoneNumber: '',
		permissions: [],
		country: Country.BH,
		kitchen: [Kitchen.BH001],
		group: ''
	},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	refetchFoods: () => {},
	setIsFilterOpen: () => false
});

function App() {
	const { isMobile, isTablet } = useDocumentMedia();
	const [isOpen, setIsOpen] = useState(true);
	const [kitchen, setKitchen] = useState<Kitchen>(getKitchenFromLocalStorage() as Kitchen);
	const [date, setDate] = useState(format('yyyy-MM-dd')(getActiveWorkingDayDate()));
	const [isOffline, setIsOffline] = useState(false);
	const [notificationReq, setNotificationReq] = useState({
		lockTimeNotificationReq: { cxRequest: 0, customerRequest: 0 },
		logisticsNotificationReq: { cxRequest: 0, customerRequest: 0 },
		shortagesNotificationReq: 0
	});
	const [shift, setShift] = useState<Session>(Session.all);

	const [mealDeliveryTickets, setMealDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [manualDeliveryTickets, setManualDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [addressDeliveryTickets, setAddressDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [cancelDeliveryTickets, setCancelDeliveryTickets] = useState<DeliveryTicketWithIndex[]>([]);
	const [openFilters, setOpenFilters] = useState(false);
	const { numberOfPendingShortages } = useListComponentByRangeQuery({
		kitchen,
		startDate: date,
		endDate: date,
		filters: { shortages: true }
	});

	useEffect(() => {
		if (isMobile || isTablet) {
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	}, [isMobile, isTablet]);

	const {
		loading: componentLoading,
		data: foodComponentsData,
		error: fcError
	} = useQuery(LIST_FOOD_COMPONENTS_QUERY, {
		variables: { kitchen, date }
	});

	const {
		loading: foodLoading,
		data: foodsData,
		error: fError,
		refetch: refetchFoods
	} = useQuery(LIST_FOODS_QUERY, {
		variables: { kitchen, date },
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const { loading: kitchenSessionLoading, data: kdsKitchens } = useQuery<ListKitchensQuery>(LIST_KITCHENS_QUERY, {
		variables: { kitchen },
		onError: () => {
			toast.error('Error while loading Kitchen Sessions');
		},
		onCompleted: (data) => {
			const kSession = data.listKitchens?.data?.find((kitchenSession) => kitchenSession.id === kitchen)?.sessions;
			if (kSession === 'all') {
				setShift(Session.all);
			} else {
				setShift(Session.morning);
			}
		}
	});

	const kdsKitchen = useMemo(
		() => kdsKitchens?.listKitchens?.data?.find((KDSKitchen) => KDSKitchen.id === kitchen),
		[kdsKitchens, kitchen]
	);

	useEffect(() => {
		if (fcError) {
			toast.error('Error while loading food components');
			console.error(fcError);
		}
		if (fError) {
			toast.error('Error while loading foods');
			console.error(fError);
		}
	}, [fcError, fError]);

	useSubscription(SUBSCRIBE_TO_UPDATE_FOOD_COMPONENT_STATUS_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_UPDATE_FOOD_PORTIONING_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_BATCH_UPDATE_FOODS_PORTIONING_STATUS_SUBSCRIPTION);
	useSubscription(SUBSCRIBE_TO_UPDATE_SHOPPING_MENU_SUBSCRIPTION);

	useEffect(() => {
		getMoreData();
		getMoreDataAddress();
	}, []);

	useEffect(() => {
		window.addEventListener('offline', () => {
			setIsOffline(true);
			toast.dismiss('toastify-online');
			toast(<OfflineAlert isOffline={true} />, {
				className: 'toastify-offline',
				hideProgressBar: true,
				closeOnClick: false,
				position: toast.POSITION.TOP_CENTER,
				closeButton: false,
				autoClose: false,
				toastId: 'toastify-offline'
			});
		});
		window.addEventListener('online', () => {
			setIsOffline(false);
			toast.update('toastify-offline', {
				render: <OfflineAlert isOffline={false} />,
				closeOnClick: true,
				autoClose: 3000,
				toastId: 'toastify-online',
				transition: Flip
			});
		});

		return () => {
			// eslint-disable-next-line unicorn/no-invalid-remove-event-listener
			window.removeEventListener('offline', () => {
				setIsOffline(false);
			});
			// eslint-disable-next-line unicorn/no-invalid-remove-event-listener
			window.removeEventListener('online', () => {
				setIsOffline(true);
			});
		};
	}, []);

	I18n.putVocabulariesForLanguage('en', {
		Username: 'Enter your email or phone number with country code',
		Password: 'Enter your password'
	});

	const { subscribeToMore: subscribeToMoreMeals, refetch: refetchMeal } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: {
					type: DeliveryTicketType.meal,
					kitchen: kitchen,
					date: { lte: date, gte: date }
				}
			} as unknown as GetDeliveryTicketsReq
		},
		onCompleted: (data) => {
			setMealDeliveryTickets(data?.listDeliveryTickets.data);
			// setMealDeliveryTickets(data?.listDeliveryTickets.data)
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	const { refetch: refetchManual } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: {
					type: DeliveryTicketType.manual,
					kitchen: kitchen,
					date: { lte: date, gte: date }
				}
			} as unknown as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setManualDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const { subscribeToMore: subscribeToMoreAddress, refetch: refetchAddress } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: {
					kitchen: kitchen,
					date: { lte: date, gte: date },
					type: DeliveryTicketType.address
				}
			} as unknown as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setAddressDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});

	const { refetch: refetchCancel } = useQuery(LIST_DELIVERY_TICKETS_QUERY, {
		variables: {
			query: {
				filters: {
					kitchen: kitchen,
					date: { lte: date, gte: date },
					type: DeliveryTicketType.cancel
				}
			} as unknown as Partial<GetDeliveryTicketsReq>
		},
		onCompleted: (data) => {
			setCancelDeliveryTickets(data?.listDeliveryTickets.data);
		},
		onError: (error) => {
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite',
		fetchPolicy: 'network-only'
	});
	useSubscription(SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION);

	const getMoreData = () => {
		subscribeToMoreMeals({
			document: SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscriberData = subscriptionData.data.subscribeToDeliveryTicketChanges;
				if (!subscriberData) return prev;
				const prevData: DeliveryTicketWithIndex[] = Array.isArray(prev?.listDeliveryTickets?.data)
					? prev.listDeliveryTickets.data
					: [];
				const isDeliveryTicketExist = prevData.find(
					(deliveryTicket: DeliveryTicketWithIndex) => deliveryTicket.id === subscriberData.id
				);
				const isDeliveryTicketValid =
					(subscriberData.data.type === DeliveryTicketType.meal || subscriberData.data.type === DeliveryTicketType.manual) &&
					subscriberData.kitchen === kitchen &&
					subscriberData.date === date;
				refetchFoods();
				if (!isDeliveryTicketExist && isDeliveryTicketValid) {
					return {
						...prev,
						listDeliveryTickets: {
							...prev.listDeliveryTickets,
							data: [subscriberData, ...prevData]
						}
					};
				}
				refetchManual();
				refetchMeal();

				return prev;
			}
		});
	};

	const getMoreDataAddress = () => {
		subscribeToMoreAddress({
			document: SUBSCRIBE_TO_DELIVERY_TICKET_CHANGES_SUBSCRIPTION,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const subscriberData = subscriptionData.data.subscribeToDeliveryTicketChanges;
				const prevData: DeliveryTicketWithIndex[] = Array.isArray(prev?.listDeliveryTickets?.data)
					? prev.listDeliveryTickets.data
					: [];
				const isDeliveryTicketExist = prevData.find(
					(deliveryTicket: DeliveryTicketWithIndex) => deliveryTicket.id === subscriberData.id
				);
				const isDeliveryTicketValid =
					(subscriberData.data.type === DeliveryTicketType.cancel || subscriberData.data.type === DeliveryTicketType.address) &&
					subscriberData.kitchen === kitchen &&
					subscriberData.date === date;

				if (!isDeliveryTicketExist && isDeliveryTicketValid) {
					return {
						...prev,
						listDeliveryTickets: {
							...prev.listDeliveryTickets,
							data: [subscriberData, ...prevData]
						}
					};
				}
				refetchCancel();
				refetchAddress();

				return prev;
			}
		});
	};

	const { data: shoppingMenuData } = useQuery(GET_SHOPPING_MENU_QUERY, {
		variables: { id: date, kitchen },
		skip: !kitchen,
		refetchWritePolicy: 'overwrite'
	});

	useMemo(() => {
		const logisticsPendingReq = [...addressDeliveryTickets, ...cancelDeliveryTickets].filter(
			(ticket) => ticket.status === DeliveryTicketStatus.pending
		).length;
		const lockTimePendingReq = [...mealDeliveryTickets, ...manualDeliveryTickets].filter(
			(ticket) => ticket.status === DeliveryTicketStatus.pending
		).length;

		if (logisticsPendingReq > notificationReq.logisticsNotificationReq.cxRequest) {
			toast.dismiss(NotificationType.logisticsChange);
			toast(<NotificationAlert type={NotificationType.logisticsChange} pendingReq={logisticsPendingReq} />, {
				className: 'notification-alert',
				hideProgressBar: true,
				closeOnClick: true,
				position: isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_RIGHT,
				closeButton: true,
				autoClose: 10_000,
				toastId: NotificationType.logisticsChange
			});
		}

		if (lockTimePendingReq > notificationReq.lockTimeNotificationReq.cxRequest) {
			toast.dismiss(NotificationType.lockTimeChange);
			toast(<NotificationAlert type={NotificationType.lockTimeChange} pendingReq={lockTimePendingReq} />, {
				className: 'notification-alert',
				hideProgressBar: true,
				closeOnClick: true,
				position: isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_RIGHT,
				closeButton: true,
				autoClose: 10_000,
				toastId: NotificationType.lockTimeChange
			});
		}
		if (numberOfPendingShortages > notificationReq.shortagesNotificationReq) {
			toast.dismiss(NotificationType.shortage);
			toast(<NotificationAlert type={NotificationType.shortage} pendingReq={numberOfPendingShortages} />, {
				className: 'notification-alert',
				hideProgressBar: true,
				closeOnClick: true,
				position: isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_RIGHT,
				closeButton: true,
				autoClose: 10_000,
				toastId: NotificationType.shortage
			});
		}

		setNotificationReq((old) => ({
			...old,
			logisticsNotificationReq: {
				cxRequest: [...addressDeliveryTickets, ...cancelDeliveryTickets].filter(
					(ticket) => ticket.status === DeliveryTicketStatus.pending
				).length,
				customerRequest: [...addressDeliveryTickets, ...cancelDeliveryTickets].filter(
					(ticket) => ticket.data?.autoApprove && !ticket.downloadedAt
				).length
			},
			lockTimeNotificationReq: {
				cxRequest: [...mealDeliveryTickets, ...manualDeliveryTickets].filter(
					(ticket) => ticket.status === DeliveryTicketStatus.pending
				).length,
				customerRequest: [...mealDeliveryTickets, ...manualDeliveryTickets].filter(
					(ticket) => ticket.data?.autoApprove && !ticket.downloadedAt
				).length
			},
			shortagesNotificationReq: numberOfPendingShortages
		}));
	}, [
		cancelDeliveryTickets,
		addressDeliveryTickets,
		mealDeliveryTickets,
		manualDeliveryTickets,
		date,
		kitchen,
		numberOfPendingShortages
	]);

	useEffect(() => {
		setOpenFilters(false);
	}, [kitchen, date]);
	const foods = (foodsData?.listFoods?.data || []) as Food[];
	const foodComponents = (foodComponentsData?.listFoodComponents?.data || []) as FoodComponent[];
	const shoppingMenu = shoppingMenuData?.getShoppingMenu;

	return (
		<ThemeProvider theme={caloTheme}>
			<Authenticator components={SigninComponents} hideSignUp={true}>
				{({ signOut, user }) => {
					const mappedUser = parseCognitoUser(user);
					return (
						<AppContext.Provider
							value={{
								isOffline: isOffline,
								isSidebarOpened: isOpen,
								isFilterOpen: openFilters,
								user: mappedUser,
								refetchFoods,
								setIsFilterOpen: () => setOpenFilters(false)
							}}
						>
							<BrowserRouter>
								<Sidebar
									isOpen={isOpen}
									setIsOpen={setIsOpen}
									signOut={signOut}
									date={date}
									setDate={setDate}
									kitchen={kitchen}
									setKitchen={setKitchen}
									user={mappedUser}
									notificationReq={notificationReq}
								/>
								<TopBar
									setIsOpen={setIsOpen}
									isOpen={isOpen}
									kitchen={kitchen}
									date={date}
									openFilters={openFilters}
									setOpenFilters={setOpenFilters}
								/>
								{(componentLoading || foodLoading || kitchenSessionLoading) && (
									<Box sx={{ width: '100%' }}>
										<LinearProgress />
									</Box>
								)}
								<ToastContainer limit={3} />
								<Box
									sx={{
										overflow: 'auto',
										height: '100%',
										width: { sm: isOpen ? `calc(100% - ${260}px)` : '100%' },
										ml: { sm: isOpen ? '260px' : '0' }
									}}
								>
									<TableContainer sx={{ display: 'inline-flex', height: '100%', width: '100%' }}>
										<Routes>
											<Route
												path={RoutePaths.componentsCheck}
												element={
													<ProtectedRoute
														component={ComponentsCheck}
														permission={KDSPermission.VIEW_COMPONENTS_CHECK}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.mealsStatus}
												element={
													<ProtectedRoute
														component={MealsStatus}
														permission={KDSPermission.VIEW_MEALS_STATUS}
														componentProps={{ shift, kitchen, foods, foodComponents }}
													/>
												}
											/>
											<Route
												path={RoutePaths.portioningDisplay}
												element={
													<ProtectedRoute
														component={PortioningDisplay}
														permission={KDSPermission.VIEW_PORTIONING_DISPLAY}
														componentProps={{
															shift,
															foods,
															foodComponents,
															kitchen,
															shoppingMenu,
															date
														}}
													/>
												}
											/>
											<Route
												path={RoutePaths.assemblyCheck}
												element={
													<ProtectedRoute
														component={AssemblyCheck}
														permission={KDSPermission.VIEW_ASSEMBLY_CHECK}
														componentProps={{ shift, foods, foodComponents, kitchen }}
													/>
												}
											/>
											<Route
												path={RoutePaths.wastageTracking}
												element={
													<ProtectedRoute
														component={WastageTracking}
														permission={KDSPermission.VIEW_WASTAGE_TRACKING}
														componentProps={{ foodComponents }}
													/>
												}
											/>
											<Route
												path={RoutePaths.customerComplaints}
												element={
													<ProtectedRoute
														component={CustomerComplaints}
														permission={KDSPermission.VIEW_CUSTOMER_COMPLAINTS}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.qualityTracking}
												element={
													<ProtectedRoute
														component={QualityTracking}
														permission={KDSPermission.VIEW_QUALITY_TRACKING}
														componentProps={{ shift, date, kitchen, foodComponents }}
													/>
												}
											/>
											<Route
												path={RoutePaths.shortageReporting}
												element={
													<ProtectedRoute
														component={ShortageReporting}
														permission={KDSPermission.VIEW_SHORTAGE_REPORTING}
														componentProps={{ foods, foodComponents }}
													/>
												}
											/>
											<Route
												path={RoutePaths.shortageDisplay}
												element={
													<ProtectedRoute
														component={ShortageDisplay}
														permission={KDSPermission.VIEW_SHORTAGE_DISPLAY}
														componentProps={{ kitchen, date, foods }}
													/>
												}
											/>
											<Route
												path={RoutePaths.wastageArchive}
												element={
													<ProtectedRoute
														component={WastageArchive}
														permission={KDSPermission.VIEW_WASTAGE_ARCHIVE}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.preDispatchCheck}
												element={
													<ProtectedRoute
														component={PreDispatchCheck}
														permission={KDSPermission.VIEW_PRE_DISPATCH_CHECK}
														componentProps={{ kitchen, date, shift, areas: kdsKitchen?.areas }}
													/>
												}
											/>
											<Route
												path={RoutePaths.preDispatchArchive}
												element={
													<ProtectedRoute
														component={PreDispatchArchive}
														permission={KDSPermission.VIEW_PRE_DISPATCH_ARCHIVE}
														componentProps={{ kitchen, date, kitchenSession: shift }}
													/>
												}
											/>
											<Route
												path={RoutePaths.driverDispatch}
												element={
													<ProtectedRoute
														component={DriverDispatch}
														permission={KDSPermission.VIEW_DRIVER_DISPATCH}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.recipeGuideBook}
												element={
													<ProtectedRoute
														component={RecipeGuideBook}
														permission={KDSPermission.VIEW_RECIPE_GUIDEBOOK}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.lockTimeChanges}
												element={
													<ProtectedRoute
														component={LockTimeChanges}
														permission={KDSPermission.VIEW_LOCK_TIME_CHANGES}
														componentProps={{
															kitchen,
															date,
															foods,
															setNotificationReq: (cx: number, customer: number) =>
																setNotificationReq({
																	...notificationReq,
																	lockTimeNotificationReq: { cxRequest: cx, customerRequest: customer }
																}),
															notificationReq: notificationReq.lockTimeNotificationReq.customerRequest
														}}
													/>
												}
											/>
											<Route
												path={RoutePaths.logisticsChanges}
												element={
													<ProtectedRoute
														component={LogisticsChanges}
														permission={KDSPermission.VIEW_LOGISTICS_CHANGES}
														componentProps={{
															kitchen,
															date,
															setNotificationReq: (cx: number, customer: number) =>
																setNotificationReq({
																	...notificationReq,
																	logisticsNotificationReq: { cxRequest: cx, customerRequest: customer }
																}),
															notificationReq: notificationReq.logisticsNotificationReq.customerRequest
														}}
													/>
												}
											/>
											<Route
												path={RoutePaths.fileManagement}
												element={
													<ProtectedRoute
														component={FileManagement}
														permission={KDSPermission.VIEW_FILE_MANAGEMENT}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.specialRequest}
												element={
													<ProtectedRoute
														component={SpecialRequest}
														permission={KDSPermission.VIEW_SPECIAL_REQUEST}
														componentProps={{ kitchen, date }}
													/>
												}
											/>
											<Route
												path={RoutePaths.qualityMetrics}
												element={
													<ProtectedRoute
														component={QualityMetrics}
														permission={KDSPermission.VIEW_QUALITY_METRICS}
														componentProps={{ kitchen, date, shift }}
													/>
												}
											/>
											<Route
												path={RoutePaths.productivityMetrics}
												element={
													<ProtectedRoute
														component={ProductivityMetrics}
														permission={KDSPermission.VIEW_QUALITY_METRICS}
														componentProps={{ kitchen, date, shift, foods }}
													/>
												}
											/>
											<Route path={RoutePaths.home} element={<ProtectedRoute component={Home} />} />
										</Routes>
									</TableContainer>
								</Box>
							</BrowserRouter>
						</AppContext.Provider>
					);
				}}
			</Authenticator>
		</ThemeProvider>
	);
}
export default App;
