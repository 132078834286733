import { orderBy } from 'lodash-es';

import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { caloTheme } from '../../../assets/themes/calo';
import { BaggingDispatch } from '../../../libs';
import { getTableAttributes } from '../../../libs/utils/helperFunctions';
import SectionTableRow from '../SectionTableRow';

interface SectionTableProps {
	tableName: string;
	dispatches: BaggingDispatch[];
}

const SectionTable = ({ tableName, dispatches }: SectionTableProps) => {
	const theme = useTheme();
	const tableAttributes = getTableAttributes(tableName, theme);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: `${dispatches.length > 0 ? tableAttributes.color : theme.palette.neutral100}`,
			color: theme.palette.common.black,
			border: 'none'
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			fontWeight: 600
		}
	}));

	return (
		<Table>
			<TableHead>
				<TableRow>
					<StyledTableCell sx={{ p: 1, pl: 2, fontSize: '16px', width: '300px' }}>{tableAttributes.name}</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '20px', textAlign: 'center' }}>Size</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '150px', textAlign: 'center' }}>Container Name</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '30px', textAlign: 'center' }}>Orders</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '30px', textAlign: 'center' }}>Actual</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '30px', textAlign: 'center' }}>Modified</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '150px', textAlign: 'center' }}>Extra Meals</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '150px', textAlign: 'center' }}>Left Overs</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '100px', textAlign: 'center' }}>Meal Cost</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '100px', textAlign: 'center' }}>Leftover cost</StyledTableCell>
					<StyledTableCell sx={{ fontSize: '12px', minWidth: '100px', textAlign: 'center' }}>Purchasing cost</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{dispatches.length > 0 ? (
					orderBy(dispatches, ['foodName.en', 'foodSize'], ['asc', 'desc']).map((dispatch) => (
						<SectionTableRow key={dispatch.id} dispatch={dispatch} />
					))
				) : (
					<TableRow>
						<TableCell
							colSpan={9}
							sx={{
								minWidth: 150,
								p: 1,
								fontFamily: caloTheme.typography.fontFamily,
								fontSize: '28px',
								textTransform: 'uppercase',
								color: theme.palette.neutral600,
								textAlign: 'center',
								border: 'none'
							}}
						>
							No meals
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default SectionTable;
