import Box from '@mui/material/Box';

import { FoodComponentStation } from '@calo/dashboard-types';
import { MenuItem, Select } from '@mui/material';
import { useMemo, useState } from 'react';
import EmptyMessage from '../../components/EmptyMessage';
import { FoodComponent } from '../../libs';
import { useDocumentMedia } from '../../libs/utils/document';
import { getComponentsWithNonZeroCount } from '../../libs/utils/foodComponentHelper';
import StationFilter from '../QualityTracking/Desktop/StationFilter';
import WastageTable from './Desktop/WastageTable';
import WastageTableMobile from './Mobile/WastageTable';

interface WastageTrackingProps {
	foodComponents: FoodComponent[];
}

const WastageTracking = ({ foodComponents }: WastageTrackingProps) => {
	const componentsWithNonZeroCount = getComponentsWithNonZeroCount(foodComponents);
	const { isMobile } = useDocumentMedia();

	const [selectedStation, setSelectedStation] = useState(Object.keys(FoodComponentStation)[0]);
	const foodComponentsWithNonZeroCount = useMemo(() => {
		const foodComponentsWithNonZeroCount = getComponentsWithNonZeroCount(foodComponents);
		const filteredComponents = foodComponentsWithNonZeroCount.filter(
			(fc) => fc.cookingStations && fc.cookingStations[fc.cookingStations.length - 1].name === selectedStation
		);
		return filteredComponents;
	}, [foodComponents, selectedStation]);

	if (isMobile) {
		return (
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
				<Select
					sx={{
						width: '100%',
						borderRadius: '8px',
						marginY: '10px'
					}}
					MenuProps={{
						PaperProps: {
							style: {
								marginLeft: '-8px',
								maxHeight: '300px'
							}
						}
					}}
					value={selectedStation}
					onChange={(data: any) => setSelectedStation(data.target.value)}
				>
					{Object.entries(FoodComponentStation).map(([key, value]) => (
						<MenuItem key={key} value={key}>
							{value}
						</MenuItem>
					))}
				</Select>
				{foodComponentsWithNonZeroCount.length > 0 ? (
					<WastageTableMobile
						key={`wastage-tracking-${componentsWithNonZeroCount.length}`}
						foodComponents={foodComponentsWithNonZeroCount ?? []}
					/>
				) : (
					<EmptyMessage label={'No Data'} />
				)}
			</Box>
		);
	} else {
		return (
			<Box sx={{ width: '96%', minHeight: '100vh' }}>
				<StationFilter selectedStation={selectedStation} setSelectedStation={setSelectedStation} disabled={false} />
				{foodComponentsWithNonZeroCount.length > 0 ? (
					<WastageTable foodComponents={foodComponentsWithNonZeroCount ?? []} />
				) : (
					<EmptyMessage label={'No Data'} />
				)}
			</Box>
		);
	}
};

export default WastageTracking;
