import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { BaggingDispatch } from '../../../libs';
import { sumDispatchTotals } from '../../../libs/utils';

interface SectionTableRowProps {
	dispatch: BaggingDispatch;
}

const SectionTableRow = ({ dispatch }: SectionTableRowProps) => {
	const { modifiedCount, totalCount } = sumDispatchTotals(dispatch);
	return (
		<TableRow hover>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', fontWeight: 600, maxWidth: '200px' }}>{dispatch.foodName?.en}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px' }}>{dispatch.foodSize}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.packages && dispatch.packages?.length > 0 ? dispatch.packages.map((p) => p.name).join(', ') : '-'}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{totalCount}</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{totalCount + (dispatch.extra ?? 0) - modifiedCount}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{modifiedCount}</TableCell>
			<TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>{dispatch.extra} Meal</TableCell>
			<TableCell sx={{ fontSize: '14px', textAlign: 'center' }}>{dispatch.leftovers} Meal</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.foodCost ? dispatch.foodCost : 0}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>
				{dispatch.leftovers && dispatch.foodCost ? dispatch.foodCost * dispatch.leftovers : 0}
			</TableCell>
			<TableCell sx={{ pl: 2, py: 2, fontSize: '14px', textAlign: 'center' }}>{dispatch.purchasingCost || 0}</TableCell>
		</TableRow>
	);
};

export default SectionTableRow;
