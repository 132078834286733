import { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { AppContext } from '../../App';
import EmptyMessage from '../../components/EmptyMessage';
import { Kitchen, PreDispatchCheckFilters, Session, SessionInfo } from '../../libs';
import { BAGGING_STATUS_QUERY } from '../../libs/graphQL';

import { makeCustomMealRepresentative } from '../../libs/utils/helperFunctions';
import ExportButton from './ExportButton';
import FilterButton from './FilterButton';
import SectionTable from './SectionTable';
import { doesFoodMatchFilters } from './helpers';

interface PreDispatchCheckProps {
	areas?: string[];
	kitchen: Kitchen;
	date: string;
	shift: Session;
}

const PreDispatchCheck = ({ kitchen, date, shift, areas }: PreDispatchCheckProps) => {
	const [filters, setFilters] = useState<PreDispatchCheckFilters>(getDefaultFilters(shift));

	const [sessionInfo, setSessionInfo] = useState<SessionInfo>({
		dispatches: [],
		kitchen: '',
		date: ''
	});

	const appContext = useContext(AppContext);

	const { loading: Loading } = useQuery(BAGGING_STATUS_QUERY, {
		variables: { kitchen, date, session: filters.shift },
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setSessionInfo(data.baggingStatus ? { ...data.baggingStatus } : {});
			setFilters(getDefaultFilters(filters.shift));
		},
		onError: (error) => {
			setSessionInfo({ kitchen: '', date: '', dispatches: [] });
			toast.error(error.message);
		},
		refetchWritePolicy: 'overwrite'
	});

	const dispatches = useMemo(() => {
		let dispatches = sessionInfo.dispatches ?? [];

		dispatches = dispatches.filter((dispatch) => {
			return doesFoodMatchFilters(dispatch, filters);
		});

		const custom = dispatches.filter((f) => f.isCustomFood);
		const nonCustom = dispatches.filter((f) => !f.isCustomFood);

		return custom.length > 0 ? [...nonCustom, makeCustomMealRepresentative(custom, filters.cities)] : nonCustom;
	}, [sessionInfo.dispatches, filters]);

	function getDefaultFilters(shift?: string): PreDispatchCheckFilters {
		return {
			shift,
			cities: [],
			mealTypes: [],
			onlySandwiches: false
		};
	}

	if (Loading && !appContext.isOffline) {
		return (
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={Loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		);
	}

	return (
		<Box sx={{ width: '96%' }}>
			{sessionInfo.dispatches || !appContext.isOffline ? (
				<Stack direction="column" gap={2.5} sx={{ ml: '20px', my: '5px', width: '100%' }}>
					<Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={1.5}>
						<FilterButton areas={areas ?? []} shift={shift} filters={filters} setFilters={setFilters} />
						<ExportButton kitchen={kitchen} date={date} filters={filters} />
					</Stack>
					<Box sx={{ width: '100%' }}>
						{dispatches.length > 0 ? (
							<SectionTable dispatches={dispatches} filters={filters} />
						) : (
							<Box sx={{ height: '500px' }}>
								<EmptyMessage label={'no data'} />
							</Box>
						)}
					</Box>
				</Stack>
			) : (
				<Box sx={{ height: '500px' }}>
					<EmptyMessage label={'no data'} />
				</Box>
			)}
		</Box>
	);
};

export default PreDispatchCheck;
